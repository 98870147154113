@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap");

:root {
  --font-family: "Work Sans", sans-serif;
  --black-color: #000000;
  --white-color: #ffffff;
  --orange-color: rgb(246, 141, 46);
  --gray-color: #545959;
  --baby-pink: #fde8d5;
  --red-color: #ff0000;
  --placeholder: #9fa3a3;
}

.red {
  color: var(--red-color);
  font-weight: bold;
  font-family: var(--font-family);
}

.yellow {
  color: var(--orange-color);
  font-weight: bold;
  font-family: var(--font-family);
}

@media screen and (max-width: 768px) {
  .schedule .modal-content {
    width: 100% !important;
  }

  .schedule .modal-content table {
    width: 100% !important;
  }
}

.section {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.pagesection {
  margin-top: 30px;
  margin-bottom: 30px;
}

.sub-section {
  margin-bottom: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: var(--font-family) !important;
}

.title {
  font-size: 32px;
  font-family: var(--font-family);
  color: var(--black-color);
  font-weight: 600;
}

a {
  text-decoration: none !important;
}

.title {
  font-size: 32px;
  font-family: var(--font-family);
  color: var(--black-color);
  font-weight: 600;
}

.sub-title {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--gray-color);
}

.solidbtn {
  color: var(--white-color);
  background-color: var(--orange-color);
}

/*-- Navbar-- */

/* .rtn-fix-nav {
   background: var(--white-color);
   position: fixed;
   z-index: 9999;
   width: 100%;
 } */

.top-nav-sec {
  background: var(--black-color);
  display: flex;
  justify-content: end;
  padding: 10px;
}

.soc-icn i {
  color: var(--black-color);
  background: var(--orange-color);
  font-size: 13px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: 4px;
  border: none;
}

.soc-icn i:hover {
  color: var(--orange-color);
  background: var(--white-color);
  border: none;
}

/*-------------------- main Navbar css-------------------- */
/* nav ul li.active a {
  color: blue;
  font-weight: bold;
} */

.active {
  border-bottom: 2px solid var(--orange-color);
  color: var(--orange-color);
}

/* .nav-link.active {
  color: red;
} */

.nav-itm-lst li {
  list-style-type: none;
  display: inline-block;
  margin: 5px 15px;
}

.nav-itm-lst li a {
  color: var(--black-color) !important;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-family);
}

.nav-itm-lst li a:hover {
  color: var(--orange-color) !important;
}

.lock-icn {
  position: relative;
  display: inline-block;
}

.lock-icn i {
  font-size: 22px;
  color: var(--black-color);
}

.lock-icn .badge {
  position: absolute;
  top: -11px;
  right: -9px;
  padding: 2px 5px;
  border-radius: 50%;
  background: orangered;
  color: var(--white-color);
  font-size: 12px;
}

/*-------------------------- Footer css------------------------- */

.foot-top-sec {
  background: var(--black-color);
}

.foot-logo {
  width: 147px;
  height: 138px;
}

.foot-logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.foot-btm-sec {
  background: var(--orange-color);
}

.sub-foot a {
  color: var(--black-color);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.foot-link li {
  list-style: none;
}

.foot-link li a {
  font-family: var(--font-family);
  text-decoration: none;
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.foot-link li p {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
}

/*------------------ schedule section css------------------ */

.scdl-sec {
  background: var(--orange-color) !important;
  color: var(--white-color) !important;

  /* background: var(--baby-pink); */
  border-radius: 8px;
  padding: 2rem 1rem 1rem;
}

.scdl-sec:hover {
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  transition: 0.3s ease;
}

@media only screen and (max-width: 810px) {
  .more-btn {
    text-align: start !important;
    margin-top: -2rem !important;
  }
  .scdl-sec {
    margin: 1rem;
  }

  .cnct-us-img {
    display: none;
  }

  .med-oly {
    margin-bottom: 1rem;
  }
}

.scdl-sec i {
  font-size: 40px;
  margin-bottom: 1.5rem;
  color: var(--white-color) !important;
}

.scdl-sec h5 {
  font-size: 20px;
  font-weight: 600;
}

.scdl-sec p {
  font-size: 14px;
  font-weight: 500;
}

/*------------------------ pretour section css---------------------- */

.more-btn {
  text-align: end;
}

.more-btn button {
  background: var(--orange-color) !important;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-family);
  padding: 4px 10px;
  color: var(--white-color) !important;
  margin-top: 1rem;
  border-radius: 5px !important;
}

.more-btn button:hover {
  background: var(--baby-pink);
  color: var(--black-color);
}

/*------------------------- pretour/daytour card css----------------------- */

.crd-img {
  height: 200px;
}

.crd-img:hover {
  filter: brightness(150%);
}

.crd-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.crd-txt h5 {
  font-size: 20px;
  font-weight: 600;
  color: var(--black-color);
}

.crd-txt p {
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-color);
}

.tours-crd {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media only screen and (max-width: 810px) {
  .tours-crd {
    margin-top: 2.5rem;
  }
}

.card-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sub-title {
  font-size: 18px !important;
  font-weight: 300 !important;
  color: var(--gray-color) !important;
}

.card-btn a {
  background: var(--orange-color);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-family);
  padding: 4px 10px;
  color: var(--white-color);
  border-radius: 5px !important;
}

.card-btn a:hover {
  background: var(--orange-color);
  color: var(--white-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card-btn a:active {
  background: var(--orange-color) !important;
  color: var(--white-color) !important;
  border: none !important;
}

.accommodation-btn {
  background: var(--orange-color);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-family);
  padding: 4px 10px;
  color: var(--white-color);
  border-radius: 5px !important;
  margin-left: 1rem;
}

.accommodation-btn:hover {
  background: var(--orange-color);
  color: var(--white-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.accommodation-btn:active {
  background: var(--orange-color) !important;
  color: var(--white-color) !important;
  border: none !important;
}

/*---------------------- Accomodation------------------ */

.acc-mod-img {
  height: 500px;
}

.acc-mod-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.acc-mod-crd {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  transition: opacity 0.5s ease-in-out;
  padding: 1.5rem;
}

.overlay-reg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: start;
  justify-content: left;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  padding: 1.5rem;
  border-radius: 5px;
}

.overlay-reg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: start;
  justify-content: left;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  padding: 1.5rem;
  border-radius: 5px;
}

.reg-page {
  position: relative;
}

.nav-itm-lst li a {
  color: var(--black-color) !important;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-family);
}

.nav-itm-lst li a:hover {
  color: var(--orange-color) !important;
}

.lock-icn i {
  font-size: 22px;
  color: var(--black-color);
}

/*-------------------------- Footer css------------------------- */

.foot-top-sec {
  background: var(--black-color);
}

.foot-logo {
  width: 147px;
  height: 138px;
}

.foot-logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.foot-btm-sec {
  background: var(--orange-color);
}

.sub-foot a {
  color: var(--black-color);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.foot-link li {
  list-style: none;
}

.foot-link li a {
  font-family: var(--font-family);
  text-decoration: none;
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

/*------------------ schedule section css------------------ */

.scdl-sec {
  background: var(--baby-pink);
  border-radius: 8px;
  padding: 2rem 1rem 1rem;
}

@media only screen and (max-width: 810px) {
  .scdl-sec {
    margin: 1rem;
  }
}

@media only screen and (max-width: 810px) {
  .more-btn {
    text-align: start !important;
    margin-top: -2rem !important;
  }

  .med-oly {
    margin-bottom: 1rem;
  }

  .pre-tour-img {
    height: 350px !important;
    width: 345px !important;
  }
}

.scdl-sec i {
  font-size: 40px;
  margin-bottom: 1.5rem;
  color: var(--black-color);
}

.scdl-sec h5 {
  font-size: 20px;
  font-weight: 600;
}

.scdl-sec p {
  font-size: 14px;
  font-weight: 500;
}

/*------------------------ pretour section css---------------------- */

.more-btn {
  text-align: end;
}

.more-btn button {
  background: var(--baby-pink);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-family);
  padding: 4px 10px;
  color: var(--black-color);
  margin-top: 1rem;
  border-radius: 5px !important;
}

.more-btn button:hover {
  background: var(--baby-pink);
  color: var(--black-color);
}

/*------------------------- pretour/daytour card css----------------------- */

.crd-img {
  height: 200px;
}

.crd-img:hover {
  filter: brightness(150%);
}

.crd-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.crd-txt h5 {
  font-size: 20px;
  font-weight: 600;
  color: var(--black-color);
}

.crd-txt p {
  font-size: 16px;
  font-weight: 500;
  color: var(--orange-color);
}

.tours-crd {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media only screen and (max-width: 810px) {
  .tours-crd {
    margin-top: 2.5rem;
  }
}

.card-btn a,
.card-btn button {
  background: var(--orange-color);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-family);
  padding: 4px 10px;
  color: var(--white-color);
  border-radius: 5px !important;
}

.card-btn a:hover,
.card-btn button:hover {
  background: var(--orange-color);
  color: var(--white-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card-btn a:active {
  background: var(--orange-color) !important;
  color: var(--white-color) !important;
  border: none !important;
}

/*---------------------- Accomodation------------------ */

.acc-mod-img {
  height: 500px;
}

.acc-mod-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.acc-mod-crd {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  transition: opacity 0.5s ease-in-out;
  padding: 1.5rem;
}

.overlay-reg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: start;
  justify-content: left;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  padding: 1.5rem;
  border-radius: 5px;
}

.reg-page {
  position: relative;
}
.reg-page p {
  font-size: 15px;
}

.overlayss {
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  height: 150px;
}

.content h5 {
  font-size: 24px;
  font-weight: 700;
  color: var(--white-color);
}

.content p {
  font-size: 16px;
  font-weight: 500;
  color: var(--white-color);
}

.content button {
  color: var(--black-color);
  background: var(--white-color);
  border-radius: 5px !important;
  border: none;
  padding: 4px 10px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
}

.content button:hover {
  color: var(--black-color);
  background: var(--white-color);
  border: none !important;
}

.modal-content {
  width: auto !important;
}

/*--------------------------- Contact US------------------------- */

.cnct-us-img {
  height: 570px;
}

.cnct-us-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.conct-sec label {
  font-size: 20px;
  font-weight: 500;
  color: var(--black-color);
}

.conct-sec span {
  color: var(--red-color);
}

.lbl {
  font-size: 20px;
  font-weight: 500;
  color: var(--black-color);
}

.lbl span {
  color: var(--red-color);
}

.conct-sec input {
  box-shadow: none !important;
  border-bottom: 1px solid var(--black-color) !important;
  border-radius: 0 !important;
  border: none;
  padding-left: 0 !important;
}

.conct-sec ::placeholder {
  color: var(--placeholder);
  font-size: 16px;
  font-weight: 400;
}

/*-------------------------- Banner section css------------------------ */

.banner {
  position: relative;
  text-align: center;
}

.banner-img {
  height: auto;
}

.banner-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logoimg {
  width: 220px;
  height: 200px;
  margin: auto;
}

.logoimg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text-ovr-img {
  position: absolute;
  top: 10%;
}

.text-fld h6 {
  font-size: 32px;
  font-weight: 600;
  color: var(--white-color);
  text-shadow: 0 1px rgb(0, 0, 0);
}

.text-fld h5 {
  font-size: 40px;
  font-weight: 700;
  color: var(--white-color);
  margin: 0;
  text-shadow: 0 1px rgb(0, 0, 0);
}

.text-fld p {
  font-size: 24px;
  font-weight: 600;
  color: var(--white-color);
  text-shadow: 0 1.5px rgb(0, 0, 0);
  /* padding-top: 1rem; */
}

/*----------------------------- Accomodation images--------------------- */

.sub-section p i {
  color: var(--orange-color);
}

.htl-vew-pnt {
  height: 425px;
  width: 550px;
}

.htl-vew-pnt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vew-pnt {
  width: 265px;
  height: 200px;
  margin-bottom: 1rem;
}

.vew-pnt img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.description h6 {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin: 2rem 0 1rem !important;
}

.description p {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: justify !important;
  margin: 0 !important;
}

.amenities h6 {
  font-size: 20px;
  font-weight: 600;
  margin: 2rem 0 1rem;
}

.amenities ul {
  padding: 0 !important;
}

.amenities li {
  list-style: none;
  line-height: 3rem;
}

.amenities li a {
  text-decoration: none;
  color: var(--black-color);
  font-size: 16px;
  font-weight: 400;
}

.amenities li a i {
  font-size: 20px;
  margin-right: 1rem;
}

.room-dtl ul p {
  margin: 0 -12px !important;
}

.room-dtl h6 {
  font-size: 20px;
  font-weight: 600;
  margin: 2rem 0 2rem;
}

@media only screen and (max-width: 810px) {
  .room-dtl h6 {
    margin: 0 !important;
  }

  .child-cnr-img {
    width: 700px !important;
    height: 400px !important;
  }

  .restro-img {
    margin-top: 2rem;
  }
}

.room-dtl li {
  font-size: 16px;
  font-weight: 400;
}

/* .restro-img {
  height: 285px;
} */

.restro-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reg-img {
  height: 150px;
}

.reg-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.reg-crd-txt p {
  color: var(--orange-color);
}

.pre-tour-img {
  height: auto;
  width: 525px;
}

.pre-tour-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pre-tour-txt h6 {
  font-size: 20px;
  font-weight: 600;
  color: var(--black-color);
}

.pre-sub-txt p {
  font-size: 20px;
  font-weight: 500;
  color: var(--orange-color);
  padding-top: 4px;
}

.pre-sub-txt button {
  border-radius: 5px !important;
  border: 1.5px solid var(--orange-color) !important;
  color: var(--orange-color);
  font-size: 16px;
  font-weight: 600;
  /* padding:0.2rem; */
}

.pre-sub-txt button:hover {
  color: var(--white-color);
  background: var(--orange-color);
}

.drop-dwn-ele li a:hover {
  color: var(--black-color) !important;
  background: transparent !important;
}

.drop-dwn-ele li a {
  color: var(--black-color) !important;
  background-color: transparent !important;
}

.child-cnr-img {
  width: 1076px;
  height: 400px;
}

.child-cnr-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top-txt h5 {
  font-size: 32px;
  font-weight: 600;
  margin: 4rem 0 1rem;
}

.top-txt p {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  color: var(--black-color);
}

.text-list h6 {
  color: var(--orange-color);
  font-size: 20px;
  font-weight: 600;
}

.text-list li {
  margin-left: 1.5rem !important;
  color: var(--black-color);
  font-size: 16px;
  font-weight: 400;
  padding: 0 0 5px;
  text-align: justify;
  font-family: var(--font-family);
}

.last-txt h6 {
  color: var(--orange-color);
  font-size: 20px;
  font-weight: 600;
}

.last-txt p {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  color: var(--black-color);
}

/*------------------------------- tourdescription page css--------------------------- */

.des-img {
  height: auto;
  overflow: hidden;
}

.des-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tour-des-pga {
  position: relative;
}

.overlay-des {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 1.5rem;
}

@media only screen and (max-width: 428px) {
  .overlay-des {
    top: 0 !important;
    padding: 0.5rem 1rem;
  }
  .acc-mod-img {
    height: 400px !important;
  }
  .des-img {
    width: 390px !important;
  }
  .all-des-ele {
    display: inline !important;
  }

  .add-cart-btn button {
    margin-top: 0.5rem;
  }

  .cnct-us-img {
    display: none;
  }

  .mobile-head {
    display: inline !important;
  }
}

.mobile-head {
  display: none;
}

.mobile-txt {
  font-size: 1rem !important;
  padding-top: 8px !important;
}

.all-des-ele {
  display: flex;
  justify-content: space-between;
}

.tour-des-pga:hover .overlay-des {
  opacity: 1;
}

.tour-des-txt h6 {
  font-size: 32px;
  color: var(--black-color);
  font-weight: 600;
  margin: 1.5rem 0 1rem;
}

.tour-des-txt p {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  /* padding-bottom: 1.5rem; */
}

.sub-dec-img {
  height: auto;
}

.sub-dec-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

@media only screen and (max-width: 810px) {
  .sub-dec-img img {
    margin-bottom: 1.5rem;
  }

  .des-img {
    width: 700px;
    height: 400px;
  }

  .overlay-des {
    top: 18rem;
  }
}

.product-img {
  width: 200px;
  height: 160px;
}

.product-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-tbl tr th {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  color: var(--black-color);
}

.product-data tr td {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-family);
}

.chek-out-sec label {
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 500;
  color: var(--black-color);
}

.chek-out-sec span {
  color: var(--red-color);
}

.chek-out-sec input {
  color: var(--black-color) !important;
  border-radius: 5px !important;
  border-color: 1px solid var(--black-color);
}

.chek-out-sec input:focus {
  box-shadow: none !important;
  border-color: 1px solid var(--black-color);
}

.chek-out-sec select {
  color: var(--black-color) !important;
  border-radius: 5px !important;
  border-color: 1px solid var(--black-color);
}

.chek-out-sec select:focus {
  box-shadow: none !important;
  border-color: 1px solid var(--black-color);
}

.check-sid-sec h6 {
  font-size: 20px;
  font-weight: 500;
  color: var(--black-color);
}

.check-sid-sec i {
  font-size: 1.5rem;
  color: var(--black-color);
}

.check-tbl tr td {
  padding-left: 0 !important;
  font-family: var(--font-family);
  font-size: 400 !important;
  font-weight: 16px !important;
  color: var(--black-color);
  line-height: 2.5rem;
}

.check-out-box {
  border: 1px solid var(--black-color);
  padding: 2rem;
  margin: 3.5rem 1rem;
}

@media only screen and (max-width: 810px) {
  .check-out-box {
    margin: 0 !important;
  }
  .prod-sd-sec {
    width: 100px !important;
  }
}

.chek-out-sec h6 {
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color);
  padding: 0 0 1rem;
}

.chek-out-sec span a {
  color: var(--orange-color);
  font-family: var(--black-color);
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.chek-out-sec button {
  color: var(--white-color);
  background: var(--orange-color);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-family);
  padding: 5px 15px;
  border-radius: 5px !important;
  border: none !important;
}

.chek-out-sec button:hover {
  background: var(--orange-color);
  color: var(--white-color);
}

.chek-out-sec button:active {
  background: var(--orange-color) !important;
  color: var(--white-color) !important;
}

.prod-sd-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
}

.dex-txt-are h6 {
  font-size: 24px;
  font-weight: 600;
  color: var(--white-color);
}

.dex-txt-are p {
  font-size: 20px;
  font-weight: 500;
  color: var(--white-color);
}

.add-cart-btn button {
  color: var(--black-color);
  background: var(--white-color);
  border-radius: 5px !important;
  border: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
}

.add-cart-btn button:hover {
  color: var(--black-color);
  background: var(--white-color);
}

.price p {
  margin-bottom: 0 !important;
  font-size: 16px;
  font-weight: 500;
}

.price h6 {
  font-size: 20px;
  font-weight: 500;
}

@media only screen and (max-width: 810px) {
  .text-ovr-img {
    top: 4%;
  }

  .reg-imgg {
    width: 700px !important;
    height: 350px !important;
  }

  .acc-mod-crd {
    margin-bottom: 1.5rem;
  }

  .vew-pnt {
    margin-top: 2rem;
  }

  .logoimg {
    width: 120px;
    height: 100px;
  }

  .text-fld h6 {
    font-size: 22px;
  }

  .text-fld h5 {
    font-size: 30px;
  }

  .text-fld p {
    font-size: 18px;
  }

  #countdown .box p {
    font-size: 18px !important;
  }

  #countdown .box .tex {
    font-size: 14px !important;
  }

  .foot-link ul {
    padding: 0 !important;
    margin-top: 1rem;
  }

  .social-med-sec {
    justify-content: left !important;
    padding: 0 !important;
  }

  .med-sec {
    margin: 2rem 0 !important;
  }

  .med-sub-sec {
    margin-bottom: 0 !important;
  }
}

.bttm-txt p {
  font-size: 15px;
  font-weight: 400;
  color: var(--black-color);
}

.contect-col {
  padding: 7rem 7rem 0rem;
}

@media only screen and (max-width: 428px) {
  .contect-col {
    padding: 1rem !important;
  }

  .text-ovr-img {
    top: 4%;
  }

  .logoimg {
    display: none;
  }

  .text-fld h6 {
    display: none;
  }

  .text-fld h5 {
    font-size: 22px;
    margin-top: 1.5rem;
  }

  .text-fld p {
    font-size: 14px;
    margin-top: -0.5rem;
  }

  #countdown {
    padding: 0 !important;
    top: 10px;
    margin-top: -1rem !important;
  }

  #countdown .box p {
    font-size: 18px !important;
  }

  #countdown .box .text {
    font-size: 10px !important;
  }

  .title {
    font-size: 22px;
  }

  .sub-title {
    font-size: 14px !important;
    margin-top: 0.5rem;
  }
}

.crtcounter-btn {
  border: 2px solid var(--white-color) !important;
}

.crtcounter-btn button {
  padding: 2px 10px !important;
  border-radius: 0 !important;
  background: var(--white-color) !important;
  color: var(--black-color) !important;
}

.text-reg h5 {
  font-size: 24px;
  font-size: 600;
}

.text-reg h6 {
  font-size: 20px;
  font-size: 500;
  color: var(--orange-color);
  padding: 1rem 0;
}

.text-reg p {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.reg-imgg {
  width: 450px;
  height: 350px;
}

.reg-imgg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prod-sd-sec i {
  color: var(--red-color);
}

.counter-btn {
  border: 2px solid var(--white-color);
}

.counter-btn button {
  padding: 2px 12px;
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0);
  color: var(--white-color);
}

.modal-content {
  background: var(--baby-pink);
}

.modal-header {
  border-color: var(--black-color);
}

.modal-title {
  color: var(--black-color);
}

.counter-btn button:hover {
  background: rgba(0, 0, 0, 0.1);
  color: white;
}

#accordion-style-1 h1,
#accordion-style-1 a {
  color: var(--orange-color);
}

#accordion-style-1 .accordion-button {
  color: var(--orange-color);
  font-weight: bold;
}

#accordion-style-1 .accordion-button:after {
  order: -1;
  margin-left: 0;
  margin-right: 0.5em;
  color: orange !important;
}

#accordion-style-1 .accordion-button:focus {
  border-color: var(--orange-color) !important;
  box-shadow: none;
}

#accordion-style-1 .btn-link {
  font-weight: 400;
  color: var(--orange-color);
  background-color: transparent;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: bold;
  padding-left: 25px;
}

.accordion-button:not(.collapsed) {
  color: var(--orange-color) !important;
  background-color: white !important;
}

.accordion-button::after {
  width: 0px !important;
}

#accordion-style-1 .accordion-body {
  border-top: 2px solid var(--orange-color);
}

#accordion-style-1 .accordion-header .btn.collapsed .fa.main {
  display: none;
}
#accordion-style-1 .accordion-header {
  background-color: white !important;
}
#accordion-style-1 .accordion-header .btn .fa.main {
  background: var(--orange-color);
  padding: 13px 11px;
  color: #ffffff;
  width: 35px;
  height: 41px;
  position: absolute;
  left: -1px;
  top: 10px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  display: block;
}

.contact-cards .card {
  padding: 1rem;
  background: rgb(1, 1, 5);
  background: linear-gradient(
    72deg,
    rgba(1, 1, 5, 1) 76%,
    rgba(246, 141, 46, 1) 100%
  );
}

.contact-cards .contact {
  display: flex;
  flex-direction: row;
  /* row-gap: 100px; */
  column-gap: 1rem;
}

.contact-right {
  margin-left: auto;
  width: 8rem;
  height: 8rem;
  /* -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box; */
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
}

.gallery img {
  transition: all 0.4s ease-in-out;
}
.gallery img:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.contact-right img {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}

#email {
  color: var(--orange-color) !important;
}

#tel {
  color: var(--white-color);
}

.download-link {
  background-color: var(--orange-color);
  color: var(--white-color);
}

.download-link:hover {
  background-color: rgb(246, 141, 46, 0.9);
  color: var(--white-color);
}

/* .download-link {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.download-link:hover {
  background-color: #0056b3;
} */

/* Payment CSS */

#payment-error .container,
#payment-success .container {
  max-width: 500px;
  margin: 0 auto;
  padding-top: 100px;
  margin-bottom: 50px;
}

.failure-message {
  text-align: center;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.failure-icon {
  color: #dc3545;
  font-size: 50px;
  margin-bottom: 20px;
}

.failure-title {
  color: #dc3545;
  font-size: 24px;
  margin-bottom: 20px;
}

.failure-text {
  color: #6c757d;
  font-size: 18px;
  margin-bottom: 30px;
}

.back-button {
  text-align: center;
}

.success-message {
  text-align: center;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.success-icon {
  color: #28a745;
  font-size: 50px;
  margin-bottom: 20px;
}

.success-title {
  color: #28a745;
  font-size: 24px;
  margin-bottom: 20px;
}

.success-text {
  color: #6c757d;
  font-size: 18px;
  margin-bottom: 30px;
}

.note {
  color: var(--orange-color);
}
